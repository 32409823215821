





















































import { BTabs, BTab, BButton } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import proponentModel from "@/api/proponent.model";
import documentModel from "@/api/document.model";
import updateHistoryModel from "@/api/update_history.model";
import EntityForm from "../entity/EntityForm.vue";
import { format } from "date-fns/esm";

@Component({
	components: { EntityCrud, EntityForm, BTabs, BTab, BButton },
})
export default class Proponents extends Vue {
	model: any = proponentModel;
	documentModel: any = documentModel;
	updateHistoryModel: any = updateHistoryModel;
	proponentTabTitle = "Dados do proponente";
	updatesTabTitle = "Histórico de atualizações";
	documentsTabTitle = "Documentos";

	title = "Proponentes";
	tableColumns = ["user.name", "user.email", "cnpj"];
	enableDelete = false;
	showEntityForm = false;
	id = "";

	proponentFormColumns = [
		"user.name",
		"user.email",
		"institutionName",
		"cnpj",
		"stateCountyRegistration",
		"address",
		"phoneNumber",
		{ key: "legalRepresentative.name", updateOnly: true },
		{ key: "legalRepresentative.cpf", updateOnly: true },
		{ key: "manager.name", updateOnly: true },
		{ key: "manager.phoneNumber", updateOnly: true },
		{ key: "manager.email", updateOnly: true },
		"user.password",
	];

	docFormColumns = [...this.documentModel.docFields];

	updateHistoryFormColumns = [
		{
			key: "createdDate",
			name: "Data",
			valueTransform: (value: string) => format(new Date(value), "dd/MM/yyyy HH:mm:ss"),
		},
		{
			key: "description",
			name: "Atualização",
			sortable: false,
		},
	];

	get isCreateForm() {
		return this.id === "novo";
	}

	mounted() {
		this.id = this.$route.params.id;
	}

	@Watch("$route")
	async onRouteChange() {
		this.id = this.$route.params.id;
	}
}
