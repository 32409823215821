


















































import { BButton, BCard, BCardText, BOverlay } from "bootstrap-vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { AlertKind, requireConfirmation, showAlert } from "../../helpers";
import EntityField from "./EntityField.vue";

@Component({ components: { EntityField, BButton, BCard, BCardText, BOverlay } })
export default class EntityForm extends Vue {
	@Prop() model: any;
	@Prop({ default: true }) isMainModel: boolean; //caso seja false, o model em questão é um complemento do principal, que é tratado de acordo

	@Prop() columns: (
		| string
		| {
				key: string;
				name: string;
				readOnly?: boolean;
				updateReadOnly?: boolean;
				createOnly?: boolean;
				updateOnly?: boolean;
				validationToShow: (entity: any) => boolean;
		  }
	)[];
	@Prop({ default: () => [] }) joinKeys: string[];
	@Prop({ default: () => {} }) defaultEntity: any;
	@Prop({ default: true }) enableDelete: boolean;
	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: () => [] }) actions: {
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];
	@Prop({ default: "id" }) idParamKey: string;
	@Prop({ default: true }) showBackButton: boolean;

	entityDescription: any = [];
	entity: any = {};
	originalEntity: any = {};
	id = "";

	isEntityLoading = false;

	async mounted() {
		this.loadEntity();
	}

	@Watch("$route")
	routeChanged(route: any) {
		if (route.params[this.idParamKey] !== this.id) {
			this.loadEntity();
		}
	}

	async loadEntity() {
		this.isEntityLoading = true;
		try {
			this.entity = { ...this.defaultEntity };
			this.entityDescription = await this.model.getDescription();
			this.id = this.$route.params[this.idParamKey];
			if (this.id !== "novo") {
				this.entity = await this.model.read(this.id);
				this.updateOriginalEntity();
			}
		} catch (error) {
			console.error(error);
		}
		this.isEntityLoading = false;
	}

	updateOriginalEntity() {
		this.originalEntity = Object.fromEntries(
			Object.entries(this.entity).map(([key, value]) => {
				if (Array.isArray(value)) {
					return [key, [...value]];
				}
				return [key, value];
			}),
		);
	}

	get fields() {
		if (!this.entityDescription) {
			return [];
		}

		return this.columns //
			.filter(column =>
				typeof column === "string" ? true : !column.validationToShow || column.validationToShow(this.entity),
			)
			.map(column => {
				if (typeof column === "string") {
					return this.entityDescription.find((field: any) => field.key === column) || {};
				} else {
					const fieldDescription = this.entityDescription.find((field: any) => field.key === column.key) || {};
					return {
						...fieldDescription,
						name: column.name ?? fieldDescription.name,
						readOnly: (column.readOnly || (column.updateReadOnly && this.id !== "novo")) ?? false,
						createOnly: column.createOnly,
						updateOnly: column.updateOnly,
					};
				}
			})
			.filter(column => !((column.createOnly && this.id !== "novo") || (column.updateOnly && this.id === "novo")));
	}

	get createOrUpdateButtonText() {
		return this.id === "novo" ? "Cadastrar" : "Salvar";
	}

	async createOrUpdate() {
		this.isEntityLoading = true;
		try {
			Object.keys(this.entity).forEach(key => {
				const field = this.fields.find(_field => _field.key === key);

				if (field?.kind === "number") {
					this.entity[key] = Number(this.entity[key]);
				}

				if (this.entity[key] === "") {
					this.entity[key] = null;
				}
			});

			if (this.id === "novo" || !this.entity.id) {
				const entity = await this.model.create(this.entity);
				if (this.isMainModel) {
					this.id = entity.id;
					this.$router.replace(this.$router.currentRoute.path.replace("novo", entity.id));
				}

				this.entity = await this.model.read(this.id);

				if (!this.isMainModel) {
					this.updateOriginalEntity();
				}
			} else {
				// remove propriedades não modificadas antes de enviar
				const modifiedProperties = Object.fromEntries(
					Object.entries(this.entity).filter(([key, value]) => {
						return this.originalEntity[key] instanceof Array && value instanceof Array
							? JSON.stringify(this.originalEntity[key]) !== JSON.stringify(value)
							: this.originalEntity[key] !== value;
					}),
				);
				if (!Object.keys(modifiedProperties).length) {
					throw { friendlyMessage: "Nenhuma alteração foi realizada.", kind: AlertKind.ALERT };
				}
				await this.model.update(this.entity.id, modifiedProperties);
				await this.loadEntity(); // recarrega a entidade para garantir consistência
			}
			showAlert(AlertKind.SUCCESS, "Os dados foram registrados.");
		} catch (error: any) {
			console.error(error);
			showAlert(error?.kind ?? AlertKind.ERROR, error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		}
		this.isEntityLoading = false;
	}

	async remove() {
		await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

		this.isEntityLoading = true;
		try {
			await this.model.delete(this.entity.id);
			showAlert(AlertKind.SUCCESS, "O registro foi excluído.");
			this.$router.back();
		} catch (error: any) {
			console.error(error);
			showAlert(AlertKind.ERROR, error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		}
		this.isEntityLoading = false;
	}
}
