import { CrudModel } from "./_crud";
import { crudQuery } from "./_request";

export class DocumentModel extends CrudModel {
	constructor() {
		super("admin/document");
	}

	readonly docFields: { key: string; maxFiles?: number }[] = [
		{ key: "constitutiveActDocsUrl" },
		{ key: "currentBoardDocumentationDocsUrl" },
		{ key: "rgCpfDocsUrl" },
		{ key: "cnpjDocsUrl" },
		{ key: "fgtsContributionCertificateDocsUrl" },
		{ key: "federalTaxDebtsClearanceCertificateDocsUrl" },
		{ key: "stateTaxDebtsClearanceCertificateDocsUrl" },
		{ key: "countyTaxDebtsClearanceCertificateDocsUrl" },
		{ key: "laborDebtsClearanceCertificateDocsUrl" },
	];

	async read(proponentId: string | number) {
		const documents: any[] = await crudQuery(this.endpoint, {
			search: { "Document.proponentId": proponentId },
			sort: { field: "Document.comments.createdDate", order: "DESC" },
		});

		this.description.forEach((item: any) => {
			const index = this.docFields.findIndex(docField => docField.key === item.key);
			if (index !== -1) {
				this.docFields[index]["maxFiles"] = item.maxFiles;
			}
		});

		const document = documents[0] ?? { proponent: { id: proponentId } };
		this.docFields.forEach(docField => {
			if (!document?.[docField.key]?.length) {
				document[docField.key] = [null];
			} else if (document[docField.key].length < docField.maxFiles!) {
				document[docField.key].push(null);
			}
		});

		return document;
	}
}

export default new DocumentModel();
