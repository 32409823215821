














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BFormFile } from "bootstrap-vue";

@Component({
	components: { BFormFile },
})
export default class FileObject extends Vue {
	@Prop() index: number | undefined;
	@Prop({ default: "form-file" }) kind: "form-file" | "button";

	file: any = null;

	@Watch("file")
	fileChangedWatcher() {
		this.$emit("file-changed", this.$refs.el, this.file, this.index);
	}

	clearFile() {
		this.file = null;
	}
}
