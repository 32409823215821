































































































































import Comment from "../proponents/Comment.vue";
import { BButton, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea } from "bootstrap-vue";
import { format, formatISO, parse, parseISO } from "date-fns";
import flatPickr from "vue-flatpickr-component";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/flatpickr.css";

import { FieldDescription } from "@/api/_crud";
import { AlertKind, showAlert } from "@/helpers";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import FileField from "./FileField.vue";

@Component({
	components: {
		Comment,
		flatPickr,
		BButton,
		BFormGroup,
		BFormCheckbox,
		BFormInput,
		BFormTextarea,
		vSelect,
		FileField,
		FeatherIcon,
	},
})
export default class EntityField extends Vue {
	@Prop() model: any;
	// Input option 1: field object from entity description
	@Prop() field?: any;

	// Input option 2: full entity description and field key
	@Prop() entityDescription?: FieldDescription[];
	@Prop() fieldKey?: string;

	@Prop() entity: any;
	@Prop({ default: false }) readOnly: boolean;

	// Date Mask
	dateMaskValue = "";

	get entityValue() {
		return this.entity[this.fieldDefinition.key];
	}

	get flatPickrConfig() {
		return {
			locale: Portuguese,
			enableTime: this.fieldDefinition.kind === "datetime",
			dateFormat: "Z",
			altInput: true,
			altFormat: this.fieldDefinition.kind === "datetime" ? "d/m/Y H:i" : "d/m/Y",
			allowInput: true,
		};
	}

	get fieldDefinition() {
		if (this.field) {
			return this.field;
		}
		if (this.entityDescription && this.fieldKey) {
			return this.entityDescription.find(field => field.key === this.fieldKey);
		}
		return { model: {} };
	}

	async mounted() {
		await this.loadManyToOneOptions();
		this.updateDateMaskFromEntity();
	}

	@Watch("fieldDefinition")
	async fieldDefinitionChanged() {
		await this.loadManyToOneOptions();
	}

	async loadManyToOneOptions() {
		if (this.fieldDefinition.kind === "relation") {
			await this.fieldDefinition.model.loadManyToOneOptions();
		}
	}

	@Watch("entityValue")
	updateDateMaskFromEntity() {
		if (this.fieldDefinition.kind === "datemask" && this.entityValue) {
			this.dateMaskValue = format(parseISO(this.entity[this.fieldDefinition.key]), "dd/MM/yyyy");
		}
	}

	@Watch("dateMaskValue")
	updateEntityFromDateMask() {
		if (this.dateMaskValue?.length !== 10) {
			return;
		}
		try {
			this.entity[this.fieldDefinition.key] = formatISO(
				parse(this.dateMaskValue, "dd/MM/yyyy", new Date()).setHours(12),
			);
		} catch {
			showAlert(AlertKind.ERROR, "Data inválida. Digite novamente no formato dd/mm/aaaa.");
			this.entity[this.fieldDefinition.key] = null;
			this.dateMaskValue = "";
		}
	}
}
