




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SEMBadge extends Vue {
	@Prop({ required: true }) value: string;
	@Prop({ default: "" }) valueEnum: string;

	get classColor():
		| "purple"
		| "green"
		| "orange"
		| "yellow"
		| "light-blue"
		| "dark-blue"
		| "none"
		| "light-green"
		| "light-yellow"
		| "red" {
		if (this.valueEnum === "ACTIVITIES_IN_PROGRESS") {
			return "dark-blue";
		}
		if (this.valueEnum.match(/SORTING|_SORTED/g)) {
			return "yellow";
		}
		if (this.valueEnum === "SORTED") {
			return "green";
		}
		if (
			[
				"SECOND_EVALUATION_IN_PROGRESS",
				"PROPONENT_DOCS_APPROVED",
				"BUDGET_APPROVED",
				"TECHNICAL_APPROVED",
				"PROPONENT_DOCS_WAITING",
				"BUDGET_WAITING",
				"TECHNICAL_WAITING",
				"PROPONENT_DOCS_REJECTED",
				"BUDGET_REJECTED",
				"TECHNICAL_REJECTED",
			].includes(this.valueEnum)
		) {
			return "orange";
		}
		if (this.valueEnum === "SECOND_EVALUATION_COMPLETED") {
			return "light-blue";
		}
		if (this.valueEnum === "RENDERING_ACCOUNTS_ANALYSIS") {
			return "purple";
		}
		if (this.valueEnum.includes("STEP")) {
			return "light-yellow";
		}
		if (this.valueEnum === "COMPLETED") {
			return "light-green";
		}
		if (this.valueEnum === "REJECTED") {
			return "red";
		}

		return "none";
	}
}
