
































































































































































import Comment from "../proponents/Comment.vue";
import { BButton, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BLink } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import "flatpickr/dist/flatpickr.css";
import FileObject from "./FileObject.vue";

import { FieldDescription } from "@/api/_crud";
import { uploadFile } from "@/api/_request";
import { AlertKind, showAlert } from "@/helpers";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { format } from "date-fns";
import { downloadFile } from "@/@core/utils/utils";

type DocumentStatus = "WAITING" | "APPROVED" | "REFUSED";

const DocumentStatusTranslator = {
	WAITING: {
		text: "Aguardando",
		color: "warning",
	},
	APPROVED: {
		text: "Aprovado",
		color: "success",
	},
	REFUSED: {
		text: "Recusado",
		color: "danger",
	},
};

@Component({
	components: {
		Comment,
		flatPickr,
		BButton,
		BFormGroup,
		BFormCheckbox,
		BFormInput,
		BFormTextarea,
		BLink,
		vSelect,
		FileObject,
		FeatherIcon,
	},
})
export default class FileField extends Vue {
	@Prop() model: any;
	// Input option 1: field object from entity description
	@Prop() field?: any;
	@Prop({ default: "form-file" }) kind: "form-file" | "button";

	// Input option 2: full entity description and field key
	@Prop() entityDescription?: FieldDescription[];
	@Prop() fieldKey?: string;

	@Prop() entity: any;
	@Prop({ default: true }) isDeflatedEntity: any;
	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: true }) showLabel: boolean;
	@Prop({ default: true }) showImage: boolean;

	get showFileOptions() {
		return this.entity[`${this.fieldDefinition.key}.file`] || this.entityValue.file;
	}

	created() {
		if (!this.isDeflatedEntity && !this.entityValue) {
			this.entity[this.fieldDefinition.key] = { name: null, file: null, uploadDate: null };
		}
	}

	isImage(value: string = "") {
		const extension = value.split(".").pop();
		if (!extension) {
			return false;
		}
		return ["png", "jpeg", "jpg"].includes(extension);
	}

	get getFilteredCommentsSection() {
		return this.entity?.comments?.length
			? this.entity.comments.filter((comment: any) => comment.relatedField === this.field.key)
			: [];
	}

	get getDocumentFieldStatus() {
		const status: DocumentStatus = this.entity[`documentsStatus.${this.field.key}`];
		return DocumentStatusTranslator[status] ?? DocumentStatusTranslator.WAITING;
	}

	get entityValue() {
		return this.entity[this.fieldDefinition.key];
	}

	get fieldDefinition() {
		if (this.field) {
			return this.field;
		}
		if (this.entityDescription && this.fieldKey) {
			return this.entityDescription.find(field => field.key === this.fieldKey);
		}
		return { model: {} };
	}

	imageLoadedEvent(event: any) {
		const image = event.path[0];
		image.style.display = "block";
	}

	async fileChangedEvent(el: any, fileObj: File | null) {
		if (!this.fieldDefinition.key) {
			return;
		}
		// limpa o arquivo se ele foi removido
		if (!fileObj) {
			this.removeSingleFileEvent();
			return;
		}
		// faz o upload e atualiza a entidade com a URL
		this.$store.dispatch("app/showLoading");
		try {
			const { name, file, uploadDate } = await uploadFile(fileObj);
			this.assignSingleFileObject(file, name, uploadDate);
		} catch (error: any) {
			el.$options.parent.clearFile();
			this.removeSingleFileEvent();
			showAlert(AlertKind.ERROR, error?.message);
		}
		this.$store.dispatch("app/hideLoading");
	}

	assignSingleFileObject(file: any, name: any, uploadDate: any) {
		if (this.isDeflatedEntity) {
			this.entity[`${this.fieldDefinition.key}.file`] = file;
			this.entity[`${this.fieldDefinition.key}.name`] = name;
			this.entity[`${this.fieldDefinition.key}.uploadDate`] = uploadDate;
		} else {
			this.entity[this.fieldDefinition.key].file = file;
			this.entity[this.fieldDefinition.key].name = name;
			this.entity[this.fieldDefinition.key].uploadDate = uploadDate;
		}
	}

	async fileFromMultifileChangedEvent(el: any, fileObj: File | null, multifileIndex: number) {
		if (!fileObj) {
			this.entity[this.fieldDefinition.key][multifileIndex] = null;
			return;
		}

		// faz o upload e atualiza a entidade com a URL
		this.$store.dispatch("app/showLoading");
		try {
			const file = await uploadFile(fileObj);
			this.entity[this.fieldDefinition.key].splice(multifileIndex, 1, file);
		} catch (error: any) {
			el.$options.parent.clearFile();
			this.entity[this.fieldDefinition.key][multifileIndex] = null;
			showAlert(AlertKind.ERROR, error?.message);
		}
		this.$store.dispatch("app/hideLoading");
	}

	removeMultiFileEvent(fileIndex: number) {
		this.entity[this.fieldDefinition.key].splice(fileIndex, 1);
	}

	removeSingleFileEvent() {
		this.assignSingleFileObject(null, null, null);
	}

	async downloadFileEvent(url: string, name: string) {
		await downloadFile(url, name);
	}

	openFileEvent(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}

	formatDate(date: string) {
		return format(new Date(date), "dd/MM/yyyy HH:mm");
	}

	@Watch("entityValue")
	updateMultiFileArrayFromEntity() {
		if (this.fieldDefinition.kind === "multifile" && this.entityValue) {
			if (
				this.entityValue[this.entityValue.length - 1] !== null &&
				this.entityValue.length < this.fieldDefinition.maxFiles
			) {
				this.entity[this.fieldDefinition.key].push(null);
			}
		}
	}
}
