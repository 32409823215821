
















import { Component, Prop, Vue } from "vue-property-decorator";
import { BCard, BCardText, BButton } from "bootstrap-vue";
import { formatDistanceToNowStrict } from "date-fns";
import { ptBR } from "date-fns/locale";

type UserRole = "EVALUATOR" | "PROPONENT";

const UserRoleTranslator = {
	EVALUATOR: "Avaliador",
	PROPONENT: "Proponente",
};

@Component({
	components: { BCard, BCardText, BButton },
})
export default class Comment extends Vue {
	@Prop() comment!: {
		id: string;
		createdDate: string;
		relatedField: string;
		text: string;
		"user.name": string;
		"user.role": UserRole;
		"user.email": string;
	};

	get getUserRole() {
		return UserRoleTranslator[this.comment["user.role"]];
	}

	get getTime() {
		return `Há ${formatDistanceToNowStrict(new Date(this.comment.createdDate), { locale: ptBR })}`;
	}
}
